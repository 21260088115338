//---------------------------------------------
// Load JQuery - Bootstrap 5 uses Vanilla JS
//---------------------------------------------
//import $ from 'jquery';
//import StickySidebarJS from 'sticky-sidebar/dist/sticky-sidebar.js';
//import StickySidebar from 'sticky-sidebar/dist/jquery.sticky-sidebar.js';

// =======================================================================
// Load all styles
// =======================================================================
import '../scss/main.scss';

import ScrollMagic from 'scrollmagic';

const getCurrentPageSlug =  window.location.pathname;

// =================================================================
// Site Variables
// =================================================================
var menu_toggle = document.querySelector('.nav-toggler');
var main_navigation = document.querySelector('#main-navigation');
var site_body = document.querySelector('body');

// =================================================================
// Mobile Navigation
// =================================================================
// $('.nav-toggler').on('click', function(e) {
//     if ($('.nav-toggler').hasClass('active') && main_navigation.classList.contains('active') && site_body.classList.contains('active-nav')) {
//         site_body.classList.remove('active-nav');
//         $('.nav-toggler').removeClass('active');
//         main_navigation.classList.remove('active');
//     } else {
//         site_body.classList.add('active-nav');
//         $('.nav-toggler').addClass('active');
//         main_navigation.classList.add('active');
//     }
// });

// =================================================================
// Drag Map Horizontally on Desktop
// =================================================================
var slider = document.querySelector('.map');
var map_location = document.querySelectorAll('.map .location-detail');
var isDown = false;
var locationDown = false;
var startX;
var scrollLeft;
if (slider) {
    
    slider.addEventListener('mousedown', function(e) {
        if (locationDown) return;
        e.preventDefault();
        isDown = true;
        slider.classList.add('active');
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
    });

    slider.addEventListener('mouseleave', function() {
        isDown = false;
        locationDown = false;
        slider.classList.remove('active');
    });

    slider.addEventListener('mouseup', function() {
        isDown = false;
        locationDown = false;
        slider.classList.remove('active');
    });

    slider.addEventListener('mousemove', function(e) {
        if (!isDown) return;
        e.preventDefault();
        var x = e.pageX - slider.offsetLeft;
        var walk = x - startX;
        slider.scrollLeft = scrollLeft - walk;
    });

    //map_location.forEach((location) => {

  
        $('.map .location-detail').on('mousedown', function(e) {
            isDown = false;
            locationDown = true;
            slider.classList.remove('active');
        });

        $('.map .location-detail').on('mouseenter', function(e) {
            isDown = false;
            locationDown = true;
            slider.classList.remove('active');
        });
        $('.map .location-detail').on('mouseleave', function(e) {
            isDown = false;
            locationDown = false;
            slider.classList.remove('active');
        });

    //});

}

const $slideshowNextArrowIcon = `
    <svg xmlns="http://www.w3.org/2000/svg" width="13.319" height="29.862" viewBox="0 0 13.319 29.862">
    <path d="M.136,0l9.35,12.542.136.784L0,25.658" transform="translate(2.105 2.099)" fill="none" stroke="#063149" stroke-linecap="square" stroke-miterlimit="10" stroke-width="3"/>
    </svg>`;
 
// =================================================================
// Activate all Slideshows
// =================================================================
$(document).ready(function () {
    var activeClass = 'slick-active',
        ariaAttribute = 'aria-hidden';
    $('[data-slick-slideshow]').on('init', function() {
        $('.slick-dots li:first-of-type').addClass(activeClass).attr(ariaAttribute, false);
    }).on( 'afterChange', function(event,slick,currentSlide) {
        var $dots = $('.slick-dots');
        $('li', $dots).removeClass(activeClass).attr(ariaAttribute, true);
        $dots.each(function() {
            $('li', $(this)).eq(currentSlide).addClass(activeClass).attr(ariaAttribute, false);
        } );
    } );
        $('[data-slick-slideshow]').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: `<button class="slick-next slick-arrow" aria-label="Next" type="button">${$slideshowNextArrowIcon}</button>`,
            prevArrow: `<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style="">${$slideshowNextArrowIcon}</button>`,
            dots: true,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        adaptiveHeight: true,
                        arrows: false,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        adaptiveHeight: false,
                        arrows: true,
                    }
                }
            ]
        });
        if (getCurrentPageSlug !== '/') {
            $('[data-slick-slideshow]').slick('slickSetOption', 'appendDots', $('.slide-content .slide-dots-holder'), true);
        }
});


// =================================================================
// ScrollMagic
// =================================================================
var controller = new ScrollMagic.Controller();
var revealElements = document.getElementsByClassName('scroll-reveal');
for (var i = 0; i < revealElements.length; i++) {
    new ScrollMagic.Scene({
            triggerElement: revealElements[i],
            offset: 50, // 0 = top of element, 100 = bottom of element, 50 = center of element
            triggerHook: 0.99, // when scrolled 1% into view
            reverse: false // only do once
        })
        .setClassToggle(revealElements[i], 'visible')
        .addTo(controller);

} 
if (document.getElementsByClassName('.map-inner') && document.getElementById('hand-swipe-icon')) {
    // Reveal hand swipe icon when map in viewport
    new ScrollMagic.Scene({ 
        triggerElement: ".map-inner",
        triggerHook: 0.7, // show, when scrolled 30% into view
        duration: "70%", // hide 30% before exiting view (70% + 30% from bottom)
        offset: 50 // move trigger to center of element
    })
    .setClassToggle("#hand-swipe-icon", "reveal") // add class to reveal
    .addTo(controller);
}


// =================================================================
// Toggle Downloads
// =================================================================
$(document).on('click', '.toggle-block:not(.active) h4', function(e) {
    var toggle_block = $(this).closest('.toggle-block');
    var toggle_content = $(toggle_block).find('.toggle-content');

    $('.toggle-block').removeClass('active');
    $('.toggle-content').slideUp(300);
    $('.toggle-block h4 .toggle-icon').text('+')

    $(this).find('.toggle-icon').text('-');
    toggle_block.addClass('active');
    toggle_content.slideDown(300);

});

$(document).on('click', '.toggle-block.active h4', function(e) {
    var toggle_block = $(this).closest('.toggle-block');
    var toggle_content = $(toggle_block).find('.toggle-content');

    $('.toggle-block').removeClass('active');
    $('.toggle-block h4 .toggle-icon').text('+')
    $('.toggle-content').slideUp(300);

    $(this).find('.toggle-icon').text('+');
    toggle_block.removeClass('active');
    toggle_content.slideUp(300);
});

// =================================================================
// Location Link
// =================================================================
$('.location-link').on('click', function(e) {
    e.preventDefault();
});
